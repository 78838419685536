import { IS_DEV } from "@constants";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";

import { App } from "@ui/App";
import { registerDependencies } from "@di/diProduction";
import "./styles/index.scss";
import "./models/global";

window._COMMIT_HASH = process.env.CI_COMMIT_SHA || "none";
window._FE_CONFIG_VERSION = process.env.FE_CONFIG_VERSION || "none";

registerDependencies();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

if (IS_DEV) {
  // @ts-ignore
  if (module["hot"]) {
    // @ts-ignore
    module["hot"].accept();
  }
}
